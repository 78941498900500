<template>
 <div class="s_system_plat_config_pop">
    <el-dialog :title="'新增平台佣金'" class="s-user-cash-out-dialog" top="15vh" width="800px" :close-on-click-modal="false" :visible.sync="show">
      <el-form :model="formData" label-width="140px" size="mini">
        <el-row>
          <el-col :span="12">
              <el-form-item label="选择平台类型：">
                <el-select v-model="formData.platform_id" placeholder="请选择平台类型">
                  <el-option v-for="item in platFormList"  :key="item.id" :label="item.title" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
              <el-form-item label="最小金额：">
                <el-input v-model="formData.min" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入最小金额（必须为整百的数）"></el-input>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
              <el-form-item label="基础佣金：">
                <el-input v-model="formData.price" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入佣金"></el-input>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
              <el-form-item label="买家佣金：">
                <el-input v-model="formData.buy_price" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入买家佣金"></el-input>
              </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="show = false" >取 消</el-button>
        <el-button type="primary" @click="apiAddPlatformConfigHandel">确定新增</el-button>
      </div>
    </el-dialog>  
 </div>
</template>

<script>
import { apiAddPlatformConfig } from '@/utils/api.js'
export default {
  props: {
    shopList: {
      type: Array,
      default() {
        return  [ { account: '', id: ''} ]
      }
    },
    platFormList: {
      type: Array,
      default() {
        return  [ { id: 'ALL', title: '全部'} ]
      }
    }
  },
  data() {
    return {
      show: false,
      value1: [],
      formData: {
        platform_id: '',
        min: '',
        price: '',
        buy_price: '',
      },
    }
  },
  methods: {
    onShow() {
      if(!this.formData.platform_id) this.formData.platform_id = this.platFormList[0].id
      this.show = true
    },
    onClose() {
      this.show = false
    },
    // 确认新增
    async apiAddPlatformConfigHandel() {
      let loading = this.$loading()
      const { success, data } = await apiAddPlatformConfig(this.formData).catch(() => loading.close())
      loading.close()
      if(!success) return
      this.$message.success(data.msg)
      this.show = false
      this.$emit('emitSaveSuccess')
    },
  }
}
</script>

<style lang='scss'>

</style>