<template>
  <el-card class="v_user_list">
    <div class="g-flex"  slot="header">
      <span>平台佣金配置列表</span>
      <div class="g-flex-justify-end g-flex-1">
        <el-button size="mini" type="danger" @click="addPlatConfigClick">新增平台佣金</el-button>
      </div>
    </div>
    <!-- 搜索 -->
    <el-form :inline="true" size="mini" :model="query">
      <el-form-item label="平台类型">
        <el-select v-model="query.platform_id" @change="getList()">
          <el-option :label="item.title" :value="item.id" v-for="(item, index) in platFormList" :key="index" ></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <!-- 表格 -->
    <el-table stripe size="mini" v-loading="table.loading" :data="table.rows" border>
      <el-table-column label="id">
          <template slot-scope="scope">
              <span>{{scope.row.id}}</span>
          </template>
      </el-table-column>
      <el-table-column label="平台类型" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span>{{ scope.row.platform_id | filtersPlatName(platFormList) }}</span>
          </template>
      </el-table-column>
      <el-table-column label="最小金额" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span>{{ scope.row.min }}</span>
          </template>
      </el-table-column>
      <el-table-column label="最大金额" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span>{{ scope.row.max }}</span>
        </template>
      </el-table-column>

      <el-table-column label="基础佣金" width="200" :show-overflow-tooltip="true">
        <template slot-scope="scope">
           <el-input placeholder="请输入内容" oninput="value=value.replace(/[^0-9.]/g,'')" v-model="scope.row.price"></el-input>
          </template>
      </el-table-column>

      <el-table-column label="买家佣金" width="200" :show-overflow-tooltip="true">
        <template slot-scope="scope">
           <el-input placeholder="请输入内容" oninput="value=value.replace(/[^0-9.]/g,'')" v-model="scope.row.buy_price"></el-input>
          </template>
      </el-table-column>

      <el-table-column label="操作" width="120" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="apiEditPlatformConfigHandel(scope.row)">确认修改</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 新增平台佣金 弹窗 -->
    <system-plat-config-pop ref="SystemPlatConfigPop" :platFormList="platFormList" @emitSaveSuccess="getList(false)"/>

    <el-pagination :page-sizes="[15, 30, 60, 100]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange" small hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    
  </el-card>
</template>

<script>
  import { formatDate } from '@/utils/index.js';
  import { apiGetPlatformCoinfig, apiGetShopTypeList, apiEditPlatformConfig } from '@/utils/api.js'
  import SystemPlatConfigPop from "./SystemPlatConfigPop.vue";
  // import UserListPingbiTimePop from './UserListPingbiTimePop.vue'
  export default {
    components: { SystemPlatConfigPop },
    data() {
      return {
        auth: {
          addBlack: this.$global.ApiList.includes(60),
          delBlack: this.$global.ApiList.includes(61),
        },
        platFormList: [],

        query: { search_key: 'user_id', search_val: '', platform_id: '',  page:1, limit: 15 },

        table:{ loading:false, total:0, rows:[] },

        form: false,
        radio: 1,
        areaCodeSelected: [],

        formData: {},

        templateDownUrl: '/upload/demo.xlsx'
      }
    },
    async created() {
      await this.apiGetShopTypeListHandel()
      this.getList()
    },
    filters: {
      filtersSex(sex) {
        if(sex == 1) return '男'
        return '女'
      },
      filtersPlatName(id, list) {
        let obj = list.find(item => item.id == id)
        if(obj) return obj.title
        return ''
      },
    },
    methods: {
      formatDate,
      // 新增平台佣金
      addPlatConfigClick() {
        this.$refs.SystemPlatConfigPop.onShow()
      },
      // 修改佣金
      async apiEditPlatformConfigHandel(item) {
        this.$confirm('此操作将会修改该平台佣金, 是否继续?', '修改佣金', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.table.loading = true
          const { success, data } =  await apiEditPlatformConfig(item).catch(() =>  this.table.loading = false)
          this.table.loading = false
          if(!success) return
          this.$message.success(data.msg)
          this.getList(false)
        }).catch(() => {});
      },

      // 获取平台列表
      async apiGetShopTypeListHandel() {
        const { success, data } = await apiGetShopTypeList()
        if(!success) return
        this.platFormList = this.platFormList.concat(data.list)
        this.query.platform_id = data.list[0].id
      },

      async getList(pageInit = true) {
        if (pageInit) this.query.page = 1
        this.table.loading = true
        const { success, data } = await apiGetPlatformCoinfig(this.query).catch(() => this.table.loading = false)
        if(!success) return
        this.table.loading = false
        this.table.rows = data.rows
        this.table.total = data.total
      },

      //每页数量改变
      sizeChange(limit) {
        this.query.limit = limit
        this.getList()
      },

      //设置form
      setForm(formData = { id: ''  }, status = 1) {
        if(!formData.id) return this.$refs.UserPianziListPop.onShow({ id: '' }, status)
        this.formData = JSON.parse(JSON.stringify(formData))
      },
      // 点击重置
      resetData() {
        this.query = { search_key: 'user_id', search_val: '', platform_id: 'ALL',  page:1, limit: 15 }
        this.getList()
      },
      
    }
  }
</script>

<style lang="scss">
</style>